import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    Flex,
    Heading,
    Box,
    VStack,
    Text,
    Input,
    Textarea,
    List,
    ListItem,
    ListIcon,
    Badge
} from '@chakra-ui/react';
import { FiUpload, FiFile, FiMenu, FiTrash2, FiUser } from 'react-icons/fi';
import Header from "../components/Header";
import logo from '../assets/logo.png';
import axios from 'axios';
import { Image } from '@chakra-ui/react';

const apiUrl = process.env.REACT_APP_API_URL;

function Main() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');
    const [uploadedFilesList, setUploadedFilesList] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
    const [users, setUsers] = useState(["Donatella", "Sunny"]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const [currentUserName, setCurrentUserName] = useState(users[0] || '');


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        // Set the default user when the component mounts
        const defaultUser = users[0];
        handleUserChange(defaultUser);
        setCurrentUserName(defaultUser);
        fetchUploadedFiles(defaultUser);
    }, []);


    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    const handleUserChange = async (newUser) => {
        try {
            const response = await axios.post(`${apiUrl}/change-user`, { username: newUser }, { withCredentials: true });
            if (response.status === 200) {
                setSelectedUser(newUser);
                setCurrentUserName(newUser);
                fetchUploadedFiles(newUser);
                setResponse('');
                setQuery('');
            }
        } catch (error) {
            console.error('Error changing user:', error);
        }
    };

    const fileInputRef = useRef();


    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    // Function to fetch the list of uploaded files
    const fetchUploadedFiles = async (user) => {
        try {
            const response = await axios.get(`${apiUrl}/list-uploaded-files?username=${user}`);
            if (response.data.files) {
                setUploadedFilesList(response.data.files);
            } else if (response.data.message) {
                // Handle the case where there are no files
                setUploadedFilesList([]);
                setLoadingMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
            setLoadingMessage('Failed to fetch files');
        }
    };

    const handleDeleteFile = async (filename) => {
        try {
            await axios.post(`${apiUrl}/delete-file`, {
                filename,
                username: currentUserName  // Include the current user's username in the request
            });
            // Remove the file from the list in the UI
            setUploadedFilesList(uploadedFilesList.filter((file) => file !== filename));
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('username', currentUserName); // Append the current user's username

        setIsUploading(true);
        setUploadStatus("");

        axios.post(`${apiUrl}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                setUploadStatus('Upload successful!');
                setTimeout(() => setUploadStatus(''), 2000);
                setUploadedFiles(prevFiles => [...prevFiles, selectedFile.name]);
                setIsUploading(false);
                setLoadingMessage("");
                fetchUploadedFiles(currentUserName); // Fetch updated list of files after upload
            })
            .catch(error => {
                setUploadStatus('Error uploading file');
                setIsUploading(false);
                setLoadingMessage("");
            });
    };



    const handleQuerySubmit = async () => {
        setIsSubmitting(true);
        try {
            const res = await axios.post(`${apiUrl}/query`, {
                query: query,
                username: currentUserName
            });
            setResponse(res.data.response);
        } catch (error) {
            console.error('Error fetching response:', error);
            setResponse('Error fetching response');
        }
        setIsSubmitting(false);
    };


    // Login function
    const handleLogin = async () => {
        try {
            const response = await axios.post(`${apiUrl}/login`, { password });
            if (response.status === 200) {
                localStorage.setItem('token', response.data.access_token);  // Store token
                setIsAuthenticated(true);
                setIsPasswordIncorrect(false);
            } else {
                setIsPasswordIncorrect(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsPasswordIncorrect(true);
            } else {
                alert("An error occurred during login");
            }
        }
    };

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };


    // If the user is not authenticated, display the login form
    if (!isAuthenticated) {
        return (
            <VStack spacing={4} align="center" justify="center" height="100vh">
                <Image src={logo} boxSize="150px" objectFit="cover" />
                <Input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setIsPasswordIncorrect(false);
                    }}
                    width="300px"
                    isInvalid={isPasswordIncorrect}
                    errorBorderColor="red.500"
                />
                <Button colorScheme="blue" onClick={handleLogin}>Login</Button>
            </VStack>
        );
    }

    return (
        <>
            <Header />
            <Flex minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
                {/* User Selection Panel */}
                <Box
                    width={isPanelVisible ? { base: '100%', md: '200px' } : "50px"}
                    bg="gray.100"
                    p={isPanelVisible ? 4 : 0}
                    borderRight="1px"
                    borderColor="gray.200"
                    position="relative"
                >
                    {/* Hamburger Menu */}
                    <Box
                        position="absolute"
                        top="10px"
                        left={isPanelVisible ? "16px" : "16px"}
                        onClick={togglePanel}
                        cursor="pointer"
                    >
                        <FiMenu size={20} />
                    </Box>

                    {isPanelVisible && (
                        <VStack spacing={1} mt="30px">
                            <Badge colorScheme="blue" p={2} borderRadius="lg">
                                User: {currentUserName}
                            </Badge>
                            <Heading as="h3" size="md" mt={4}>Change User</Heading>
                            <List spacing={2}>
                                {users.map((user, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        cursor="pointer"
                                        onClick={() => handleUserChange(user)}
                                    >
                                        <ListIcon as={FiUser} color="blue.500" mr={2} />
                                        {user}
                                    </ListItem>
                                ))}
                            </List>
                            {/* Logout Button */}
                            <Button colorScheme="red" onClick={handleLogout}>Logout</Button>
                        </VStack>
                    )}
                </Box>

                {/* Submit Query Section */}
                <Box flex={1} m={5}>
                    <VStack align="start" spacing={4}>
                        <Heading as="h3" size="lg" mt={isPanelVisible ? 0 : 4}>Find in PDF</Heading>
                        <Textarea
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Can you find me information about... Choose the language of your choice"
                            size="sm"
                        />
                        <Button
                            colorScheme="blue"
                            onClick={handleQuerySubmit}
                            isLoading={isSubmitting}
                            loadingText='Fetching'
                        >
                            Find
                        </Button>
                        {/* Render the response box only if there is a response */}
                        {response && (
                            <Box bg="gray.100" p={3} borderRadius="md" w="full">
                                <Text>{response}</Text>
                            </Box>
                        )}
                    </VStack>
                </Box>

                {/* Add a PDF Section */}
                <Box flex={1} m={5}>
                    <VStack align="start" spacing={4}>
                        <Heading as="h3" size="lg">Add a PDF</Heading>
                        <Input type="file" onChange={handleFileChange} accept=".pdf" ref={fileInputRef} hidden />

                        <Button leftIcon={<FiUpload />} colorScheme="blue" onClick={handleFileButtonClick}>
                            Choose File
                        </Button>

                        {selectedFile && <Text fontSize="sm">{selectedFile.name}</Text>}

                        <Button
                            colorScheme="blue"
                            onClick={handleFileUpload}
                            isLoading={isUploading}
                            loadingText='Processing...'
                            disabled={!selectedFile}
                        >
                            Upload File
                        </Button>

                        {!isUploading && uploadStatus === 'Upload successful!' && <Text> Upload successful ! Your document has been processed successfully.</Text>}

                        <Box bg="gray.100" p={3} borderRadius="md" w="full" overflow="auto">
                            <Heading as="h4" size="md" mb={3}>Bibliography</Heading>
                            {uploadedFilesList.length > 0 ? (
                                <List spacing={2}>
                                    {uploadedFilesList.map((file, index) => (
                                        <ListItem key={index} display="flex" alignItems="center">
                                            <ListIcon as={FiFile} color="blue.500" />
                                            {/* Add word-break or overflow-wrap here */}
                                            <Box mr={2} wordBreak="break-word">{file}</Box>
                                            <ListIcon as={FiTrash2} color="red.500" marginLeft="auto" cursor="pointer" onClick={() => handleDeleteFile(file)} />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Text wordBreak="break-word">{loadingMessage}</Text> // Display the message here
                            )}
                        </Box>

                    </VStack>
                </Box>
            </Flex>
        </>
    );
}

export default Main;
